.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.fullscreen-bg__video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
}

.flipbook-container {
  width: 99%;
  /* padding: 3rem; */
  margin: 2rem auto;
  border-top: 3px solid #d1111a;
  border-left: 3px solid #d1111a;
  border-right: 3px solid #0e2542;
  border-bottom: 3px solid #0e2542;
}

.pdf-flipbook-container {
  /* width: "54%"; */
  /* background-color: #d1111a; */
}

.html-flip-book {
  width: 50%;
  margin: auto;
  /* background-color: #0056b3; */
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 2rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 80%;
  margin: 2rem auto;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border-top: 3px solid #d1111a;
  border-left: 3px solid #d1111a;
  border-right: 3px solid #0e2542;
  border-bottom: 3px solid #0e2542;
  cursor: pointer;
}

.body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #333;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.title {
  text-align: center;
  margin-bottom: 40px;
}
.milestone-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}
.milestone {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.milestone:hover {
  box-shadow: 0 0 10px #d1111a;
}
.milestone h3 {
  color: #0056b3;
  margin-bottom: 10px;
}
.milestone p {
  margin: 0;
  font-size: 14px;
}

.milestone h3 {
  color: #0e2542;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.milestone {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  opacity: 0; /* Başlangıçta görünmez */
  transform: translateY(20px); /* Başlangıçta biraz yukarıda */
  animation: fadeIn 0.6s ease-out forwards; /* Animasyon uygulama */
}

/* Her milestonun animasyonun başlama zamanını rastgele yapmak için */
.milestone:nth-child(1) {
  animation-delay: 0.1s;
}
.milestone:nth-child(2) {
  animation-delay: 0.15s;
}
.milestone:nth-child(3) {
  animation-delay: 0.2s;
}
.milestone:nth-child(4) {
  animation-delay: 0.25s;
}
.milestone:nth-child(5) {
  animation-delay: 0.3s;
}
.milestone:nth-child(6) {
  animation-delay: 0.35s;
}
.milestone:nth-child(7) {
  animation-delay: 0.4s;
}
.milestone:nth-child(8) {
  animation-delay: 0.45s;
}
.milestone:nth-child(9) {
  animation-delay: 0.5s;
}
.milestone:nth-child(10) {
  animation-delay: 0.55s;
}
.milestone:nth-child(11) {
  animation-delay: 0.6s;
}
.milestone:nth-child(12) {
  animation-delay: 0.65s;
}
.milestone:nth-child(13) {
  animation-delay: 0.7s;
}
.milestone:nth-child(14) {
  animation-delay: 0.75s;
}
.milestone:nth-child(15) {
  animation-delay: 0.8s;
}
.milestone:nth-child(16) {
  animation-delay: 0.85s;
}
.milestone:nth-child(17) {
  animation-delay: 0.9s;
}
.milestone:nth-child(18) {
  animation-delay: 0.95s;
}
.milestone:nth-child(19) {
  animation-delay: 1s;
}
.milestone:nth-child(20) {
  animation-delay: 1.1s;
}
.milestone:nth-child(21) {
  animation-delay: 1.15s;
}
.milestone:nth-child(22) {
  animation-delay: 1.25s;
}
.milestone:nth-child(23) {
  animation-delay: 1.3s;
}
.milestone:nth-child(24) {
  animation-delay: 1.35s;
}
.milestone:nth-child(25) {
  animation-delay: 1.4s;
}

.milestone:nth-child(26) {
  animation-delay: 1.45s;
}
.milestone:nth-child(27) {
  animation-delay: 1.5s;
}
.milestone:nth-child(28) {
  animation-delay: 1.55s;
}
.milestone:nth-child(29) {
  animation-delay: 1.6s;
}
.milestone:nth-child(30) {
  animation-delay: 1.65s;
}
.milestone:nth-child(31) {
  animation-delay: 1.7s;
}
.milestone:nth-child(32) {
  animation-delay: 1.75s;
}
.milestone:nth-child(33) {
  animation-delay: 1.8s;
}
.milestone:nth-child(34) {
  animation-delay: 1.85s;
}
.milestone:nth-child(35) {
  animation-delay: 1.9s;
}
/* İhtiyaca göre daha fazla ekleyebilirsiniz */

/* galeri css */
.image-gallery-image {
  width: 100%;
  height: 500px; /* İstediğiniz yükseklik */
  object-fit: cover; /* Resimlerin aynı boyutta görünmesi için */
}

.image-gallery-thumbnail-image {
  width: 80px;
  height: 80px;
  object-fit: cover; /* Thumbnail resimlerin boyutlara sığması için */
}
